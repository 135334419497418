<script>
import { Doughnut } from 'vue-chartjs'
 
export default {
    extends: Doughnut,

    props: [
        "rarity",
    ],

    data: () => ({
        list: []
    }),

    async mounted() {
        this.list = Object.values(this.rarity)
        this.createGraph()
    },

    methods: {
        // Chart 생성
        createGraph() {
            this.renderChart(
                {
                    labels: [
                        'UR 울트라레어',
                        'HR 하이퍼레어',
                        'SAR 스페셜아트레어',
                        'SSR 샤이니슈퍼레어',
                        'SR 슈퍼레어',
                        'CSR 캐릭터슈퍼레어',
                        'CHR 캐릭터레어',
                        'PROMO 프로모',
                        'RRR 트리플레어',
                        'RR 더블레어',
                        'R 레어',
                        '기타'
                    ],

                    datasets: [
                        {
                            data: this.list,
                            backgroundColor: [
                                '#FFF12A',
                                '#fe512b',
                                '#FFA033',
                                '#F7FF00',
                                '#11FF00',
                                '#00DCFF',
                                '#4D6BFF',
                                '#000000',
                                '#FFC0CB',
                                '#E6E6FA',
                                '#FFB6C1',
                                '#ADD8E6'
                            ]
                        }
                    ]
                },

                {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    }
                }
            )
        }
    }
}
</script>