<template>
    <div>
        <Header
            :user="user"
        />

        <!-- 콜렉션북의 총 가치 -->
        <v-sheet
            class="rounded-15 pt-5 pb-2 mx-5 mb-2"
        >
            <div class="d-flex align-center ml-6">
                <v-sheet
                    height="32"
                    width="32"
                    class="mr-2 custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon size="16">mdi-currency-krw</v-icon>
                </v-sheet>

                <v-sheet>
                    <p
                        class="font-weight-bold mb-0"
                        style="font-size:16px;"
                    >
                        내 콜렉션북의 총 가치는?
                    </p>
                </v-sheet>
            </div>

            <v-sheet class="mt-3 mb-5">
                <p
                    class="text-center font-weight-bold mb-0 primary--text"
                    style="font-size:22px;"
                >
                    {{$toComma(price_total.value)}}원
                </p>
                <p
                    class="text-center font-weight-light mb-0"
                    style="color:#adafca; font-size:9px;"
                >
                    <!-- {{new Date(price_total.update_date)}} 갱신 기준 -->
                    {{new Date().toLocaleString()}} 갱신 기준
                </p>
            </v-sheet>

            <p class="font-weight-light mb-0 text-center" style="color:#adafca; font-size:9px; line-height:13px;">
                콜렉션의 재미를 더하기 위한 참고용 지표입니다 재미로만 봐주세요 제발
            </p>
        </v-sheet>

        <!-- 국가별 수집률 -->
        <v-sheet
            class="px-5 py-4 pb-4 rounded-15 mx-5 mb-2"
        >
            <p
                style="font-size:13px; font-weight:bold;"
            >
                국가별 수집률
            </p>

            <!-- 국내판 -->
            <v-sheet
                class="d-flex align-center mx-4 mt-5"
            >
                <v-sheet
                    height="44"
                    width="44"
                    class="mb-4 custom_shadow rounded-circle d-flex justify-center align-center grow"
                >
                    <v-img
                        height="20"
                        width="20"
                        contain
                        src="@/assets/collection/kr.png"
                    ></v-img>
                </v-sheet>

                <v-progress-linear
                    color="primary"
                    background-color="grey lighten-3"
                    rounded
                    style="width:324px;"
                    class="rounded-10 shrink ml-7 mb-4"
                    height="20"
                    :value="collection.kr_percentage"
                ></v-progress-linear>

                <p
                    class="ml-5 mb-14px; font-weight-bold primary--text"
                    style="font-size:13px;"
                >
                    {{collection.kr_percentage}}%
                </p>
            </v-sheet>

            <!-- 북미판 -->
            <v-sheet
                class="d-flex align-center mx-4"
            >
                <v-sheet
                    height="44"
                    width="44"
                    class="mb-4 custom_shadow rounded-circle d-flex justify-center align-center grow"
                >
                    <v-img
                        height="20"
                        width="20"
                        contain
                        src="@/assets/collection/en.png"
                    ></v-img>
                </v-sheet>

                <v-progress-linear
                    color="primary"
                    background-color="grey lighten-3"
                    rounded
                    style="width:324px;"
                    class="rounded-10 shrink ml-7 mb-4"
                    height="20"
                    :value="collection.en_percentage"
                ></v-progress-linear>

                <p
                    class="ml-5 mb-14px; font-weight-bold primary--text"
                    style="font-size:13px;"
                >
                    {{collection.en_percentage}}%
                </p>
            </v-sheet>

            <!-- 일본판 -->
            <v-sheet
                class="d-flex align-center mx-4"
            >
                <v-sheet
                    height="44"
                    width="44"
                    class="mb-4 custom_shadow rounded-circle d-flex justify-center align-center grow"
                >
                    <v-img
                        height="20"
                        width="20"
                        contain
                        src="@/assets/collection/jp.png"
                    ></v-img>
                </v-sheet>

                <v-progress-linear
                    color="primary"
                    background-color="grey lighten-3"
                    rounded
                    style="width:324px;"
                    class="rounded-10 shrink ml-7 mb-4"
                    height="20"
                    :value="collection.jp_percentage"
                ></v-progress-linear>

                <p
                    class="ml-5 mb-14px; font-weight-bold primary--text"
                    style="font-size:13px;"
                >
                    {{collection.jp_percentage}}%
                </p>
            </v-sheet>
        </v-sheet>

        <!-- 내가 모은 카드 장수 -->
        <v-sheet
            class="rounded-10 d-flex align-center px-10 mx-5 mb-2"
            color="#23D2E2"
            height="48"
        >
            <p class="mb-0" style="font-size:11px; vertical-align:middle; color:white;">
                내가 모은 카드 장수
            </p>

            <v-spacer></v-spacer>

            <p class="mb-0" style="font-size:20px; vertical-align:middle; color:white; font-weight:bold; text-align:end;">
                {{$toComma(collection.total_card_amount)}}장
            </p>
        </v-sheet>

        <!-- 내가 모은 카드 종류 -->
        <v-sheet
            class="rounded-10 d-flex align-center px-10 mx-5 mb-2"
            color="#615DFA"
            height="48"
        >
            <p class="mb-0" style="font-size:11px; vertical-align:middle; color:white;">
                내가 모은 카드 종류
            </p>

            <v-spacer></v-spacer>

            <p class="mb-0" style="font-size:20px; vertical-align:middle; color:white; font-weight:bold; text-align:end;">
                {{$toComma(collection.total_card)}}장
            </p>
        </v-sheet>

        <!-- 등급별 보유 현황 -->
        <v-sheet
            class="px-5 py-4 rounded-15 mx-5 mb-2"
        >
            <p
                style="font-size:13px; font-weight:bold; margin-bottom:0px;"
            >
                등급별 보유 현황
            </p>
            
            <ChartDoughnut
                :height="200"
                :rarity="rarity"
                :key="componentKey"
            />
        </v-sheet>

        <!-- 카드 장수 -->
        <v-sheet
            class="px-5 py-4 rounded-15 mx-5 mb-2"
        >
            <p
                style="font-size:13px; font-weight:bold;"
            >
                카드 장수
            </p>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    color="#FFF12A"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon color="white" small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    UR 울트라레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.ur_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    style="background: linear-gradient(to bottom, #FF2424, #FEB03B, #FFFF29, #3DE264, #59E1F1, #B969C2, #FF4444);"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon color="white" small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    HR 하이퍼레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.hr_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    color="#FFA033"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon color="white" small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    SAR 스페셜아트레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.sar_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    color="#F7FF00"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon color="white" small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    SSR 샤이니슈퍼레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.ssr_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    color="#11FF00"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon color="white" small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    SR 슈퍼레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.sr_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    color="#00DCFF"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon color="white" small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    CSR 캐릭터슈퍼레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.csr_s_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    color="#4D6BFF"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon color="white" small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    CHR 캐릭터레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.csr_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    color="#000000"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon color="white" small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    PROMO 프로모
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.promo_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    RRR 트리플레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.rrr_amount}}장
                </p>
            </div>
            
            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    RR 더블레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.rr_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    R 레어
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.r_amount}}장
                </p>
            </div>

            <div
                class="d-flex align-center py-1 px-5"
            >
                <v-sheet
                    height="28"
                    width="28"
                    class="custom_shadow rounded-circle d-flex justify-center align-center"
                >
                    <v-icon small>mdi-bookmark-box-multiple-outline</v-icon>
                </v-sheet>

                <p
                    class="ml-4 mb-0"
                    style="font-size:12px;"
                >
                    기타
                </p>

                <v-spacer></v-spacer>

                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:11px;"
                >
                    {{rarity.etc_amount}}장
                </p>
            </div>
        </v-sheet>

        <v-sheet
            class="px-5 pt-4 pb-2 rounded-15 mx-5 mb-2"
            height="240"
        >
            <p
                style="font-size:13px; font-weight:bold;"
            >
                월별 콜렉션 총 가치
            </p>

            <!-- 그래프 -->
            <Chart
                v-if="price_total.value"
                :height="160"
                :price="price_total.value"
                :user_id="user_id"
            />
        </v-sheet>

        <v-sheet
            class="px-5 py-4 rounded-15 mx-5 mb-2"
            height="170"
        >
            <div class="d-flex">
                <p
                    style="font-size:13px; font-weight:bold;"
                >
                    콜렉션북 갤러리
                </p>

                <v-spacer></v-spacer>
                <p
                    class="mx-1"
                    style="font-size:10px; cursor:pointer;"
                    @click="openBig()"
                >
                    크게 보기
                </p>
                <p
                    class="mx-1"
                    style="font-size:10px; cursor:pointer;"
                    @click="dialog.image = true"
                >
                    사진 업로드
                </p>
            </div>

            <!-- 콜렉션북 갤러리 -->
            <div
                class="d-flex justify-start pb-4"
                style="width:100%; overflow-x:auto"
            >
                <div
                    v-for="item in gallery_list" :key="item"
                >
                    <v-img
                        contain
                        class="d-flex"
                        width="96"
                        height="96"
                        :src="'/upload/collection/gallery/'+item.image"
                    ></v-img>
                </div>
            </div>
        </v-sheet>

        <!-- 콜렉션북 안내문구 -->
        <v-sheet
            class="rounded-10 d-flex align-center justify-center px-4 mx-5 mb-4"
            height="40"
        >
            <v-icon color="#ADAFCA" class="mr-2">mdi-information-outline</v-icon>
            <p
                class="my-0"
                style="font-size:10px; line-height:12px;"
            >
                콜렉션북의 콜렉션%는 카드DB의 추가/수정 등의 이유로 달라질 수 있습니다.
            </p>
        </v-sheet>

        <BannerView
            v-if="$store.state.ads_banner.collection"
            type="collection"
            width="100%"
            :height="46/360*100+'vw'"
            :round="'rounded-0'"
        />

        <!-- 콜렉션북 갤러리 이미지 업로드 -->
        <v-dialog
            v-model="dialog.image"
            width="auto"
            content-class="rounded-xl"
        >
            <v-sheet
                class="pa-6"
                style="z-index:1 !important;"
            >
                <table class="table_style">   
                    <!-- 이미지 편집/미리보기 -->       
                    <tr>
                        <td class="pa-3">
                            <clipper-basic
                                :src="url"
                                ref="clipper"
                                class="my-clipper"
                                :init-width="100"
                                :init-height="100"
                            >
                                <div class="placeholder" slot="placeholder"></div>
                            </clipper-basic>
                        </td>
                    </tr>

                    <!-- 파일 입력 -->
                    <tr>
                        <td class="py-2 px-3">
                            <v-sheet
                                @drop.prevent="addDropFile"
                                @dragover.prevent
                            >
                                <v-file-input
                                    placeholder="카드 이미지를 선택해주세요"
                                    outlined
                                    dense
                                    show-size
                                    hide-details
                                    prepend-icon
                                    color="primary"
                                    v-model="uploadImage"
                                    @change="onFileChange()"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
                                    </template>
                                </v-file-input>
                            </v-sheet>
                        </td>
                    </tr>

                    <!-- 제출 버튼 -->
                    <tr>
                        <td class="py-2 px-3">
                            <v-btn
                                @click="submit()"
                                depressed
                                width="100%"
                                dark
                                color="primary"
                            >
                                이미지 등록하기
                            </v-btn>
                        </td>
                    </tr>
                </table>
                
                <!-- 저작권법 안내문구 -->
                <v-sheet
                    height="24"
                    color="#F8468D"
                    class="rounded-lg pt-1 mt-3"
                >
                    <p
                        class="white--text text-center mb-0 pt-2px"
                        style="font-size:10px; letter-spacing:-0.5px;"
                    >
                        타인이 촬영한 사진을 무단으로 사용시 저작권법 위반으로 처벌 될 수 있습니다.
                    </p>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <v-dialog
            v-model="dialog.image_big"
            width="600"
            content-class="rounded-xl"
        >
            <v-sheet
                class="pa-4 rounded-xl"
                style="border:2px solid #4ed6e2"
            >
                <v-img
                    contain
                    width="568"
                    max-height="720"
                    class="rounded-10"
                    :src="'/upload/collection/gallery/'+selected_image"
                >
                    <v-btn
                        style="position:absolute; right:12px; top:12px; color:white; font-weight:400;"
                        depressed
                        color="#adafca"
                        small
                        class="rounded-xl"
                        @click="deleteGallery(selected_image)"
                    >
                        삭제
                    </v-btn>
                </v-img>
                <div
                    class="d-flex justify-start pb-4 mt-2"
                    style="width:100%; overflow-x:auto"
                >
                    <v-sheet
                        v-for="item in gallery_list" :key="item"
                        class="py-1 px-2 mr-2 rounded-10"
                        outlined
                        @click="selected_image = item.image"
                    >
                        <v-img
                            contain
                            class="d-flex rounded-10"
                            width="64"
                            height="96"
                            :src="'/upload/collection/gallery/'+item.image"
                        ></v-img>
                    </v-sheet>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import Header from "../components/Header"
import ChartDoughnut from "../components/ChartDoughnut"
import Chart from "../components/Chart"
import BannerView from '@/components/BannerView'

export default {
    components: {
        Header,
        ChartDoughnut,
        Chart,
        BannerView
    },

    data: () => ({
        price_total: {
            value: 0,
            update_date: null
        },

        collection: {
            kr_percentage: 0,
            en_percentage: 0,
            jp_percentage: 0,
            total_card_amount: 0,
            total_card: 0,
        },

        rarity: {
            ur_amount: 0,
            hr_amount: 0,
            sar_amount: 0,
            ssr_amount: 0,
            sr_amount: 0,
            csr_s_amount: 0,
            csr_amount: 0,
            promo_amount: 0,
            rrr_amount: 0,
            rr_amount: 0,
            r_amount: 0,
            etc_amount: 0,
        },

        user: {},

        select_list: {
            card_nation: [
                { name: "국내판", value: "kr" },
                { name: "북미판", value: "en" },
                { name: "일본판", value: "jp" } 
            ] 
        },
        
        uploadImage: {},
        url: "",
        card_nation: "kr",

        gallery_list: [],

        dialog: {
            image: false,
            image_big: false
        },

        componentKey: 0,

        selected_image: null,
        user_id: ""
    }),

    mounted(){
        if(this.$route.query.user_id){
            this.user_id = this.$route.query.user_id
        }else{
            this.user_id = this.$store.state.user_id
        }
        
        // 콜렉션북 대시보드 값 불러오기
        this.load()

        // 콜렉션북 총 가치 불러오기
        // this.loadTotalPrice()

        // 유저 정보 불러오기
        this.importUserProfile()

        if(!this.$store.state.is_logined){
            alert("로그인한 회원만 이용할 수 있습니다")
            this.$router.push('/collection/list')
        }

        this.loadGallery()
    },

    methods: {
        deleteGallery(image){
            this.$http.post("/api/new/collection/delete/gallery", {
                params:{
                    image: image
                }
            }).then((res) => {
                console.log(res)
                this.loadGallery()
                this.dialog.image_big = false
            })
        },

        openBig() {
            this.dialog.image_big = true
            this.selected_image = this.gallery_list[0].image
        },
        
        loadGallery(){
            this.$http.post('/api/new/collection/gallery/select', {
                params: {
                    user_id: this.user_id,
                }
            }).then((res) => {
                this.gallery_list = res.data
            })
        },

        // 콜렉션북 대시보드 값 불러오기
        load(){
            this.$http.post("/api/new/collection/dashboard/kr", {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.collection.kr_percentage = res.data[0].percentage.toFixed(2)
            })

            this.$http.post("/api/new/collection/dashboard/en", {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.collection.en_percentage = res.data[0].percentage.toFixed(2)
            })

            this.$http.post("/api/new/collection/dashboard/jp", {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.collection.jp_percentage = res.data[0].percentage.toFixed(2)
            })

            this.$http.post("/api/new/collection/dashboard/total", {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.collection.total_card_amount = res.data[0].total_amount
                this.collection.total_card = res.data[0].total_card
            })

            this.$http.post("/api/new/collection/dashboard/trade", {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.price_total.value = res.data[0].total_card_price
            })

            this.$http.post("/api/new/collection/dashboard/rarity", {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.rarity.ur_amount = res.data[0].ur_amount
                this.rarity.hr_amount = res.data[0].hr_amount
                this.rarity.sar_amount = res.data[0].sar_amount
                this.rarity.ssr_amount = res.data[0].ssr_amount
                this.rarity.sr_amount = res.data[0].sr_amount
                this.rarity.csr_s_amount = res.data[0].csr_s_amount
                this.rarity.csr_amount = res.data[0].csr_amount
                this.rarity.promo_amount = res.data[0].promo_amount
                this.rarity.rrr_amount = res.data[0].rrr_amount
                this.rarity.rr_amount = res.data[0].rr_amount
                this.rarity.r_amount = res.data[0].r_amount
                this.rarity.etc_amount = res.data[0].etc_amount

                this.componentKey++
            })
        },

        // 콜렉션북 총 가치 불러오기
        // loadTotalPrice(){
        //     this.$http.post("/api/new/collection/dashboard/price", {
        //         params: {
        //             user_id: this.user_id
        //         }
        //     }).then((res) => {
        //         this.price_total = res.data[0]
        //     })
        // },

        // 유저 정보 가져오기
        importUserProfile(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.user_id
                }
            }).then(res => {
                this.user = res.data[0]

                if(this.user.type=='사용자'){
                    if(this.user.grade==0){
                        this.user_icon =  'new'
                    }else{
                        this.user_icon =  this.user.grade
                    }
                }else if(this.user.type=='밴 유저'){
                    this.user_icon =  'banned'
                }else if(this.user.type=='관리자'){
                    this.user_icon =  'admin'
                }else if(this.user.type=='매니저'){
                    this.user_icon =  'manager'
                }else if(this.user.type=='운영진'){
                    this.user_icon =  'board_manager'
                }else if(this.user.type=='테스터'){
                    this.user_icon =  'tester'
                }
            })
        },

        // 파일 드래그앤드롭
        addDropFile(e) { 
            const file = e.dataTransfer.files[0]
            this.uploadImage = e.dataTransfer.files[0]
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },
        
        // 파일 선택시 이미지 표시
        onFileChange() {
            const file = this.uploadImage
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },

        // 이미지 업로드
        imageUpload: _.debounce(function() {
            // Crop 이미지 파일로 변환
            const canvas = this.$refs.clipper.clip() //call component's clip method
            var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
            var binaryData = atob(imgDataUrl.split(',')[1])
            var array = []
            for (var i = 0; i < binaryData.length; i++) {
                array.push(binaryData.charCodeAt(i));
            }
            var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})
            var formData = new FormData()
            formData.append("image", file)

            // 이미지 업로드
            this.$http.post('/api/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    type: "collection/gallery"
                }
            }).then((res) => {
                this.$http.post('/api/new/collection/gallery/insert', {
                    params: {
                        user_id: this.user_id,
                        image: res.data
                    }
                }).then((res) => {
                    alert("갤러리 이미지가 추가되었습니다")
                    this.dialog.image = false
                    this.loadGallery()
                })
            })
        }, 500),

        // 제출
        submit(){
            if(!this.uploadImage.name){
                alert("등록하실 이미지를 선택해주세요")
            }else if(this.card_nation == ""){
                alert("등록하실 이미지의 발매 국가를 선택해주세요")
            }else{
                this.imageUpload()
            }
        }
    }
}
</script>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 6px #EFEFEF !important;
}
</style>
<style scoped>
.table_style{
    width:340px;
    border:1px solid #ccc;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ccc;
    padding:10px;
}

.my-clipper {
    width:100%;
}

.placeholder {
    width:245px;
    height:363px;
}
</style>