<template>
    <div>
        <!-- 상단 (PC) -->
        <Header
            v-if="!$vuetify.breakpoint.mobile"
            @nationUpdated="nationUpdated_handler"
        />

        <!-- 검색조건 -->
        <v-sheet class="py-5 px-7 rounded-10">
            <!-- 제품명 정보 -->
            <v-sheet
                class="d-flex justify-space-between mb-3"
            >
                <!-- 이미지 -->
                <v-sheet
                    v-if="keyword.card_nation == 'kr'"
                    height="50"
                    width="130"
                    style="margin-right:12px;"
                >
                    <v-img
                        contain
                        height="50"
                        width="130"
                        :src="set.image? '/upload/set_kr_image/'+encodeURI(set.image):require('@/assets/logos/icon_grey.svg')"
                    ></v-img>
                </v-sheet>

                <!-- 이미지 -->
                <v-sheet
                    v-if="keyword.card_nation == 'en'"
                    height="50"
                    width="130"
                    style="margin-right:12px;"
                >
                    <v-img
                        contain
                        height="50"
                        width="130"
                        :src="set.image? '/upload/set_en_image/'+encodeURI(set.image):require('@/assets/logos/icon_grey.svg')"
                    ></v-img>
                </v-sheet>

                <!-- 이미지 -->
                <v-sheet
                    v-if="keyword.card_nation == 'jp'"
                    height="50"
                    width="130"
                    style="margin-right:12px;"
                >
                    <v-img
                        contain
                        height="50"
                        width="130"
                        :src="set.image? '/upload/set_jp_image/'+encodeURI(set.image):require('@/assets/logos/icon_grey.svg')"
                    ></v-img>
                </v-sheet>

                <!-- 이름 & 시리즈 & 발매일 -->
                <div>
                    <p
                        style="font-size:13px; font-weight:bold; color:#424361; width:290px;"
                        class="mb-1 text-truncate"
                    >
                        {{set.name}}
                    </p>
                    <p
                        style="font-size:12px; color:#424361; width:290px;"
                        class="mb-0 text-truncate"
                    >
                        <span class="mr-6">시리즈: {{set.series}}</span>
                        <span>발매일: {{new Date(set.release_datetime).toLocaleDateString().replaceAll(". ", "-").slice(0, -1)}}</span>
                    </p>
                </div>

                <v-spacer></v-spacer>

                <!-- 수집률 -->
                <div
                    class="mt-1 mr-6"
                >
                    <!-- 토탈넘버, 시크릿, 수집갯수 / 토탈넘버 -->
                    <v-sheet
                        class="d-flex justify-space-between mb-1"
                        width="200"
                    >
                        <p
                            class="mb-0 text-center text-body-1 font-weight-bold"
                        >
                            <span class="mr-1" style="vertical-align:middle;">{{parseInt(set.totalNumber)}}개</span>
                            <span class="text-caption primary--text" style="vertical-align:middle;">+{{(set.totalCard - parseInt(set.totalNumber)) < 0? 0 : (set.totalCard - parseInt(set.totalNumber))}} 시크릿</span>
                        </p>
                        <p
                            class="mb-0 text-center text-body-1 font-weight-bold"
                        >
                            <span class="text-caption" style="vertical-align:middle;">{{(set.count+set.count_secret)}} / {{parseInt(set.totalNumber)}}</span>
                        </p>
                    </v-sheet>

                    <!-- 수집률 Linear -->
                    <v-progress-linear
                        color="primary"
                        background-color="grey lighten-3"
                        :value="calculateProbability((set.count+set.count_secret), set.totalNumber)"
                    ></v-progress-linear>
                </div>

                <!-- 수집률 퍼센티지 -->
                <v-sheet
                    width="97"
                    height="32"
                    class="mt-1 d-flex justify-center align-center mx-auto rounded-20 custom_shadow"
                >
                    <span
                        class="text-body-2 font-weight-medium"
                        :class="calculateProbability((set.count+set.count_secret), set.totalCard) == 100? 'primary--text':''"
                    >
                        {{calculateProbability((set.count+set.count_secret), set.totalCard)}}%
                    </span>
                </v-sheet>
            </v-sheet>

            <v-sheet class="d-flex align-center justify-space-between">
                <div
                    class="d-flex flex-grow-1" 
                >
                    <!-- 카드타입 -->
                    <v-select
                        height="48"
                        style="width:191px;"
                        class="shrink rounded-15 font-weight-bold"
                        placeholder="전체"
                        persistent-placeholder
                        label="카드타입"
                        dense
                        outlined
                        hide-details
                        item-text="name"
                        item-value="value"
                        :items="select_list.supertype"
                        v-model="keyword.supertype"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-2">{{item.name}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-1 mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 희귀도 -->
                    <v-autocomplete
                        height="48"
                        style="width:191px;"
                        class="rounded-15 font-weight-bold flex-shrink ml-2"
                        dense
                        :items="computed_rarity_list"
                        v-model="keyword.rarity"
                        label="희귀도"
                        placeholder="전체"
                        persistent-placeholder
                        hide-details
                        outlined
                    >
                        <template v-slot:append>
                            <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </div>
                <div
                    class="d-flex flex-grow-1 ml-5px" 
                >
                    <!-- 검색어 -->
                    <v-text-field
                        style="width:100%;"
                        dense
                        outlined
                        hide-details
                        v-model="keyword.text"
                        placeholder="카드 이름"
                        class="rounded-15 font-weight-bold flex-grow"
                        height="48"
                        @keyup.enter="search()"
                    >
                        <template v-slot:append>
                            <v-icon class="pt-1 mr-1" color="#424361">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>

                    <!-- 검색 버튼 -->
                    <v-btn
                        depressed
                        dark
                        class="ml-2 rounded-10"
                        style="font-size:18px; font-weight:bold; letter-spacing:-0.6px;"
                        color="primary"
                        width="94"
                        height="48"
                        @click="search()"
                    >
                        검색
                    </v-btn>
                </div>
            </v-sheet>
        </v-sheet>

        <!-- 순서 & 전체 +1장, -1장 & 제품명 메모, list 타입 선택 -->
        <v-sheet
            class="d-flex align-center py-4 px-7 my-2 rounded-10"
        >
            <div
                class="d-flex flex-grow-1"
            >
                <!-- 번호 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold mr-2"
                    style="font-size:14px;"
                    :color="sequence == '번호'? 'primary':'#B3B3C2'"
                    width="82"
                    height="32"
                    @click="sequence == '번호'? (sequence = '번호', align_number = !align_number) : sequence = '번호'"
                >
                    <v-icon size="16" class="mr-1">{{align_number? 'mdi-sort-variant':'mdi-sort-reverse-variant'}}</v-icon>
                    번호
                </v-btn>

                <!-- 이름 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold mr-2"
                    style="font-size:14px;"
                    :color="sequence == '이름'? 'primary':'#B3B3C2'"
                    width="82"
                    height="32"
                    @click="sequence == '이름'? (sequence = '이름', align_name = !align_name) : sequence = '이름'"
                >
                    <v-icon size="16" class="mr-1">{{align_name? 'mdi-sort-variant':'mdi-sort-reverse-variant'}}</v-icon>
                    이름
                </v-btn>

                <!-- 보유수량 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold mr-2"
                    style="font-size:14px;"
                    :color="sequence == '보유수량'? 'primary':'#B3B3C2'"
                    width="103"
                    height="32"
                    @click="sequence == '보유수량'? (sequence = '보유수량', align_amount = !align_amount) : sequence = '보유수량'"
                >
                    <v-icon size="16" class="mr-1">{{align_amount? 'mdi-sort-variant':'mdi-sort-reverse-variant'}}</v-icon>
                    보유수량
                </v-btn>

                <!-- 발매일 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold"
                    style="font-size:14px;"
                    :color="sequence == '발매일'? 'primary':'#B3B3C2'"
                    width="96"
                    height="32"
                    @click="sequence == '발매일'? (sequence = '발매일', align_date = !align_date) : sequence = '발매일'"
                >
                    <v-icon size="16" class="mr-1">{{align_date? 'mdi-sort-variant':'mdi-sort-reverse-variant'}}</v-icon>
                    발매일
                </v-btn>
            </div>

            <v-btn
                text
                x-small
                :ripple="false"
                @click="all_plus()"
            >
                <v-icon color="#615DFA" class="mr-2px">mdi-plus-circle</v-icon>
                <span style="font-size:12px; letter-spacing:-0.8px;">
                    전체 +1장
                </span>
            </v-btn>

            <v-btn
                text
                x-small
                :ripple="false"
                @click="all_minus()"
            >
                <v-icon color="#615DFA" class="mr-2px">mdi-minus-circle</v-icon>
                <span style="font-size:12px; letter-spacing:-0.8px;">
                    전체 -1장
                </span>
            </v-btn>

            <v-btn
                width="96"
                height="32"
                color="#615DFA"
                dark
                depressed
                class="rounded-10 ml-2 mr-2"
                @click="dialog.set_memo = true"
            >
                제품명 메모
            </v-btn>

            <v-spacer></v-spacer>
            <!-- 목록보기 방식 선택 -->
            <div
                class="d-flex"
            >
                <!-- 카드방식 (card) -->
                <v-btn
                    icon
                    class="mt-2px"
                    v-ripple="false"
                    :color="list_type == 'card'? '#23D2E2':'#DFDFDF'"
                    @click="list_type = 'card'"
                >
                    <v-icon size="36">mdi-image-outline</v-icon>
                </v-btn>

                <!-- 목록방식 (list)  -->
                <v-btn
                    icon
                    class="ml-2 mt-2px"
                    v-ripple="false"
                    :color="list_type == 'list'? '#23D2E2':'#DFDFDF'"
                    @click="list_type = 'list'"
                >
                    <v-icon size="36">mdi-format-list-bulleted</v-icon>
                </v-btn>
            </div>
        </v-sheet>

        <!-- 검색 목록 -->
        <div
            v-if="!loading"
            class="d-flex justify-space-between mt-2"
        >
            <v-sheet
                width="100%"
                class="rounded-15 px-7 py-5"
                style="position:relative;"
            >
                <div
                    v-if="list.length"
                    class="mt-4"
                    style="margin:0 -6px;"
                >
                    <!-- 목록 방식 (card) -->
                    <v-row
                        v-if="list.length && list_type=='card'"
                        no-gutters
                    >
                        <v-col
                            v-for="(item, index) in list" :key="'card'+index"
                            cols="3"
                            class="pa-0 pb-2 mb-4"
                        >
                            <!-- 이미지 -->
                            <v-card
                                width="180"
                                style="cursor:pointer; position:relative;"
                                :style="
                                    (keyword.card_nation == 'kr' && item.amount_kr)
                                    || (keyword.card_nation == 'jp' && item.amount_jp)
                                    || (keyword.card_nation == 'en' && item.amount_en)
                                    ? '':'opacity:0.6; filter: grayscale(100%);'"
                                class="mx-auto"
                                @click="item_increase(item)"
                            >
                                <v-sheet height="248">
                                    <!-- <img width="180" height="248" src='@/assets/card.png' /> -->
                                    <img width="180" height="248" :src="view_image(item)" />
                                </v-sheet>

                                <!-- 라벨 -->
                                <v-sheet
                                    style="background:rgba(0,0,0,0.6); height:34px; width:180px; position:absolute; bottom:0px;"
                                >
                                    <p
                                        class="mt-2 mb-0 text-center px-2 text-truncate"
                                        style="font-size:12px;"
                                    >
                                        <span v-if="keyword.card_nation == 'kr'" class="white--text">{{item.number_kr.toString().padStart(3,'0')}} {{item.name_kr}}</span>
                                        <span v-if="keyword.card_nation == 'jp'" class="white--text">{{item.number_jp.toString().padStart(3,'0')}} {{item.name_jp}}</span>
                                        <span v-if="keyword.card_nation == 'en'" class="white--text">{{item.number_en.toString().padStart(3,'0')}} {{item.name_en}}</span>
                                    </p>
                                </v-sheet>
                            </v-card>

                            <!-- 희귀도 & 넘버/토탈넘버 -->
                            <v-sheet class="d-flex justify-space-between px-3 pt-2 pb-1">
                                <p
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    <span v-if="keyword.card_nation == 'kr'">{{item.rarity_kr}}</span>
                                    <span v-if="keyword.card_nation == 'jp'">{{item.rarity_jp}}</span>
                                    <span v-if="keyword.card_nation == 'en'">{{item.rarity_en}}</span>
                                </p>

                                <p
                                    v-if="keyword.card_nation == 'kr'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_kr}}/{{item.printedTotal_kr}}
                                </p>
                                <p
                                    v-if="keyword.card_nation == 'jp'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_jp}}/{{item.printedTotal_jp}}
                                </p>
                                <p
                                    v-if="keyword.card_nation == 'en'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_en}}/{{item.printedTotal_en}}
                                </p>
                            </v-sheet>

                            <v-divider class="mx-3"></v-divider>

                            <!-- 메모 & 수량 & 미보유/보유중 -->
                            <v-sheet class="d-flex align-center justify-space-between px-3 pt-6px">
                                <!-- 메모 -->
                                <v-btn
                                    v-if="keyword.card_nation == 'kr'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_kr.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                <v-btn
                                    v-if="keyword.card_nation == 'jp'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_jp.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                <v-btn
                                    v-if="keyword.card_nation == 'en'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_en.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                
                                <!-- 수량 증가/감소 -->
                                <div
                                    class="d-flex align-center"
                                >
                                    <v-icon
                                        size="16"
                                        @click="item_decrease(item)"
                                    >
                                        mdi-minus
                                    </v-icon>
                                    <p
                                        class="text-caption mx-2 mb-0 pb-2px"
                                    >
                                        <span v-if="keyword.card_nation == 'en'">{{item.amount_en}}</span>
                                        <span v-if="keyword.card_nation == 'kr'">{{item.amount_kr}}</span>
                                        <span v-if="keyword.card_nation == 'jp'">{{item.amount_jp}}</span>
                                    </p>
                                    <v-icon
                                        size="16"
                                        @click="item_increase(item)"
                                    >
                                        mdi-plus
                                    </v-icon>
                                </div>

                                <!-- 미보유/보유중 -->
                                <v-btn
                                    v-if="keyword.card_nation == 'kr'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_kr? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_kr==0? '미보유':'보유중'}}
                                </v-btn>
                                <v-btn
                                    v-if="keyword.card_nation == 'jp'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_jp? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_jp==0? '미보유':'보유중'}}
                                </v-btn>
                                <v-btn
                                    v-if="keyword.card_nation == 'en'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_en? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_en==0? '미보유':'보유중'}}
                                </v-btn>
                            </v-sheet>
                        </v-col>
                    </v-row>

                    <!-- 목록 방식 (list) -->
                    <v-row
                        v-if="list.length && list_type=='list'"
                        no-gutters
                        class="mb-4"
                    >
                        <v-col
                            v-for="(item, index) in list" :key="'list'+index"
                            cols="12"
                            class="py-2"
                        >
                            <v-sheet outlined class="px-6 py-4 d-flex justify-center align-center rounded-15">
                                <v-card
                                    width="150"
                                    style="cursor:pointer"
                                    class="mx-auto"
                                    @click="item_increase(item)"
                                >
                                    <v-sheet height="204">
                                        <!-- <img width="150" height="206" src='@/assets/card.png' /> -->
                                        <img width="150" height="206" :src="view_image(item)" />
                                    </v-sheet>
                                </v-card>

                                <v-sheet class="ml-10" width="320">
                                    <!-- 카드이름 -->
                                    <div>
                                        <!-- 영어 -->
                                        <p
                                            v-if="keyword.card_nation=='en' && item.name_en"
                                            style="font-size:24px; font-weight:600; width:320px;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="keyword.card_nation=='kr' && item.name_kr"
                                            style="font-size:24px; font-weight:600; width:320px;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="keyword.card_nation=='jp' && item.name_jp"
                                            style="font-size:24px; font-weight:600; width:320px;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_jp}}
                                        </p>
                                    </div>

                                    <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                    <div class="d-flex">
                                        <!-- 레귤레이션 -->
                                        <p
                                            v-if="item.regulationMark"
                                            style="font-size:15px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            <v-icon size="19" class="pb-2px mr-1">
                                                mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                            </v-icon>
                                        </p>

                                        <!-- 넘버, 토탈넘버 -->
                                        <div class="mr-2">
                                            <!-- 영어 -->
                                            <p
                                                v-if="keyword.card_nation=='en' && item.number_en && item.printedTotal_en"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_en}} / {{item.printedTotal_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="keyword.card_nation=='kr' && item.number_kr && item.printedTotal_kr"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_kr}} / {{item.printedTotal_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="keyword.card_nation=='jp' && item.number_jp && item.printedTotal_jp"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_jp}} / {{item.printedTotal_jp}}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="d-flex ml-2px mb-1">
                                        <!-- 희귀도 -->
                                        <div>
                                            <!-- 영어 -->
                                            <p
                                                v-if="keyword.card_nation=='en' && item.rarity_en"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="keyword.card_nation=='kr' && item.rarity_kr"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="keyword.card_nation=='jp' && item.rarity_jp"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_jp}}
                                            </p>
                                        </div>
                                    </div>

                                    <!-- 제품명 -->
                                    <div class="ml-1px">
                                        <!-- 영어 -->
                                        <p
                                            v-if="keyword.card_nation=='en' && item.set_en"
                                            style="font-size:15px; font-weight:300; width:320px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="keyword.card_nation=='kr' && item.set_kr"
                                            style="font-size:15px; font-weight:300; width:320px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="keyword.card_nation=='jp' && item.set_jp"
                                            style="font-size:15px; font-weight:300; width:320px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_jp}}
                                        </p>
                                    </div>

                                    <!-- 메모 & 수량 & 미보유/보유중 -->
                                    <v-sheet
                                        class="d-flex align-center mt-2"
                                    >
                                        <!-- 메모 -->
                                        <v-btn
                                            v-if="keyword.card_nation == 'kr'"
                                            width="40"
                                            outlined
                                            x-small
                                            :color="item.memo_kr.length? 'primary':'#B3B3C2'"
                                            @click="item_memo(item)"
                                        >
                                            메모
                                        </v-btn>
                                        <!-- 메모 -->
                                        <v-btn
                                            v-if="keyword.card_nation == 'jp'"
                                            width="40"
                                            outlined
                                            x-small
                                            :color="item.memo_jp.length? 'primary':'#B3B3C2'"
                                            @click="item_memo(item)"
                                        >
                                            메모
                                        </v-btn>
                                        <!-- 메모 -->
                                        <v-btn
                                            v-if="keyword.card_nation == 'en'"
                                            width="40"
                                            outlined
                                            x-small
                                            :color="item.memo_en.length? 'primary':'#B3B3C2'"
                                            @click="item_memo(item)"
                                        >
                                            메모
                                        </v-btn>
                                        
                                        <!-- 수량 증가/감소 -->
                                        <div
                                            class="d-flex align-center mx-3"
                                        >
                                            <v-icon
                                                size="16"
                                                @click="item_decrease(item)"
                                            >
                                                mdi-minus
                                            </v-icon>
                                            <span
                                                class="text-caption mx-2"
                                            >
                                                <span v-if="keyword.card_nation == 'kr'">{{item.amount_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'">{{item.amount_jp}}</span>
                                                <span v-if="keyword.card_nation == 'en'">{{item.amount_en}}</span>
                                            </span>
                                            <v-icon
                                                size="16"
                                                @click="item_increase(item)"
                                            >
                                                mdi-plus
                                            </v-icon>
                                        </div>

                                        <!-- 미보유/보유중 -->
                                        <v-btn
                                            v-if="keyword.card_nation == 'kr'"
                                            width="40"
                                            outlined
                                            x-small
                                            :color="item.amount_kr? 'primary':'#B3B3C2'"
                                        >
                                            {{item.amount_kr==0? '미보유':'보유중'}}
                                        </v-btn>
                                        <v-btn
                                            v-if="keyword.card_nation == 'jp'"
                                            width="40"
                                            outlined
                                            x-small
                                            :color="item.amount_jp? 'primary':'#B3B3C2'"
                                        >
                                            {{item.amount_jp==0? '미보유':'보유중'}}
                                        </v-btn>
                                        <v-btn
                                            v-if="keyword.card_nation == 'en'"
                                            width="40"
                                            outlined
                                            x-small
                                            :color="item.amount_en? 'primary':'#B3B3C2'"
                                        >
                                            {{item.amount_en==0? '미보유':'보유중'}}
                                        </v-btn>
                                    </v-sheet>
                                </v-sheet>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>

                <div
                    v-if="!list.length"
                    class="mt-6 text-center py-16"
                >
                    <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                    <p style="color:#ccc">검색 결과가 없습니다</p>
                </div>

                <div
                    class="d-flex align-center justify-space-between mt-1"
                >
                    <v-btn
                        width="98"
                        height="44"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10"
                        color="#ADAFCA"
                        @click="$router.go(-1)"
                    >
                        뒤로
                    </v-btn>
                    <v-btn
                        width="98"
                        height="44"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="ml-2 rounded-10"
                        color="#ADAFCA"
                        @click="reset()"
                    >
                        초기화
                    </v-btn>
                </div>
            </v-sheet>
        </div>

        <!-- 제품명 메모 (dialog) -->
        <v-dialog
            v-model="dialog.set_memo"
            width="440"
            content-class="rounded-15"
        >
            <v-sheet
                class="py-4 px-10"
            >
                <p
                    class="text-center"
                    style="font-size:20px; font-weight:bold; color:#424361;"
                >
                    제품명 메모
                </p>
                <v-textarea
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>

                <!-- 제품명 이름 -->
                <v-sheet class="d-flex justify-space-between px-3 pt-2 pb-1 text-body-2">
                    <p
                        class="mb-0"
                        style="color:#615DFA;"
                    >
                        {{set.name}}
                    </p>
                </v-sheet>

                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10 mx-3"
                        color="#615DFA"
                        @click="save_set_memo()"
                    >
                        저장
                    </v-btn>

                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10"
                        color="#ADAFCA"
                        @click="dialog.set_memo = false;"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 카드 메모 (dialog) -->
        <v-dialog
            v-model="dialog.memo"
            width="440"
            content-class="rounded-15"
        >
            <v-sheet
                class="py-4 px-10"
            >
                <p
                    class="text-center"
                    style="font-size:20px; font-weight:bold; color:#424361;"
                >
                    카드메모
                </p>
                <v-textarea
                    v-if="keyword.card_nation == 'kr'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_kr"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <v-textarea
                    v-if="keyword.card_nation == 'en'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_en"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <v-textarea
                    v-if="keyword.card_nation == 'jp'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_jp"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <!-- 희귀도 & 넘버/토탈넘버 -->
                <v-sheet class="d-flex justify-space-between px-3 pt-2 pb-1 text-body-2">
                    <p
                        class="mb-0"
                        style="color:#615DFA;"
                    >
                        <span v-if="keyword.card_nation == 'en'">{{selected_item.name_en}}</span>
                        <span v-if="keyword.card_nation == 'kr'">{{selected_item.name_kr}}</span>
                        <span v-if="keyword.card_nation == 'jp'">{{selected_item.name_jp}}</span>
                    </p>

                    <p
                        class="mb-0"
                    >
                        <span v-if="keyword.card_nation == 'en'">{{selected_item.rarity_en}} {{selected_item.number_en}}/{{selected_item.printedTotal_en}}</span>
                        <span v-if="keyword.card_nation == 'kr'">{{selected_item.rarity_kr}} {{selected_item.number_kr}}/{{selected_item.printedTotal_kr}}</span>
                        <span v-if="keyword.card_nation == 'jp'">{{selected_item.rarity_jp}} {{selected_item.number_jp}}/{{selected_item.printedTotal_jp}}</span>
                    </p>
                </v-sheet>

                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:11px; font-weight:bold;"
                        class="rounded-10"
                        color="#424361"
                        @click="save_memo_to_all()"
                    >
                        <div>
                            전체카드에<br/>
                            저장
                        </div>
                    </v-btn>
                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10 mx-3"
                        color="#615DFA"
                        @click="save_memo()"
                    >
                        저장
                    </v-btn>

                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10"
                        color="#ADAFCA"
                        @click="dialog.memo = false;"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 500px; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="100"
        ></v-progress-circular>
    </div>
</template>
<script>
import Header from '../components/Header'

export default {
    components: {
        Header
    },

    data: () => ({
        select_list: {
            // 희귀도
            rarity: {
                en: [],
                kr: [],
                jp: []
            },

            // 슈퍼타입
            supertype: [
                {name: '모든 카드',  value:'ALL'},
                {name: '에너지',  value:'Energy'},
                {name: '포켓몬', value:'Pokémon'},
                {name: '트레이너스', value:'Trainer'}
            ]
        },

        // 제품명 정보
        set: {
            count: 0,
            count_secrete: 0,
            id: 0,
            image: "",
            name: "",
            release_datetime: null,
            series: "",
            totalCard: 0,
            totalNumber: "",
            
            // 제품명 메모
            memo: ""
        },

        // 검색 키워드
        keyword: {
            card_nation: "kr",
            set_name: "",
            rarity: "",
            supertype: "ALL",
            text: "",
        },

        // 목록
        list: [],

        sequence: "번호",
        list_type: "card",
        selected_list: [],

        dialog: {
            memo: false,
            set_memo: false
        },

        selected_item: {
            memo: ""
        },

        align_number: false,
        align_name: false,
        align_amount: false,
        align_date: false,

        loading: false,

        memo_all: ""
    }),

    computed:{
        // 계산된 희귀도 목록
        computed_rarity_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.rarity.en
                case 'kr' : return this.select_list.rarity.kr
                case 'jp' : return this.select_list.rarity.jp
            }
        },
    },

    watch: {
        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_number(){
            this.sortList()
        },

        // 순서
        align_name(){
            this.sortList()
        },

        // 순서
        align_amount(){
            this.sortList()
        },

        // 순서
        align_date(){
            this.sortList()
        }
    },

    async mounted() {
        // 집계현황(제품명 목록)에서 클릭한 발매국가 가져와서 적용하기 
        this.keyword.card_nation = this.$route.query.nation

        // 집계현황(제품명 목록)에서 클릭한 제품명 가져와서 적용하기 
        this.keyword.set_name = this.$route.query.set_name

        // 희귀도 목록 불러오기
        await this.loadRarityList()

        // 검색
        await this.search()

        // 제품명 정보 가져오기
        await this.load_set_info()
    },

    methods: {
        // 제품명 메모
        save_set_memo(){
            this.set.memo = this.memo_all
            this.$http.post("/api/new/collection/update/set/memo", {
                params: {
                    user_id: this.$store.state.user_id,
                    set_name: this.set.name,
                    card_nation: this.$route.query.nation,
                    memo: this.set.memo
                }
            }).then((res) => {
                // console.log(res)
                alert("제품명 메모가 저장되었습니다.")
                this.dialog.set_memo = false
            })
        },

        // 제품명 정보 가져오기
        load_set_info(){
            this.loading = true
            this.$http.post("/api/new/collection/select/set/search/"+this.keyword.card_nation+"/specific", {
                params: {
                    card_nation: this.keyword.card_nation,
                    keyword: this.$route.query.set_name,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                // console.log("set", res.data[0])
                this.set = res.data[0]
                // this.memo_all = this.set.memo
                this.loading = false
            })
        },

        // 이미지
        view_image(item){
            if(this.keyword.card_nation == "kr"){
                if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }

            else if(this.keyword.card_nation == "jp")
            {
                if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }

            else if(this.keyword.card_nation == "en")
            {
                if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }
        },

        // 희귀도 목록 불러오기
        loadRarityList(){
            this.$http.post('/api/card/select/rarity')
            .then(res => {
                this.select_list.rarity.en = res.data.map(e => e.en).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.kr = res.data.map(e => e.kr).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.jp = res.data.map(e => e.jp).filter(e => e!=null && e!="-" && e!="")
            })
        },

        // 국가선택 $Emit Handler
        nationUpdated_handler(card_nation){
            this.keyword = {
                card_nation : card_nation,
                set_name: "",
                rarity: "",
                supertype: "ALL",
                text: ""
            }

            this.search()
        },

        // 카드메모 > 열기
        item_memo(item){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            this.selected_item.set_kr = item.set_kr
            this.selected_item.set_en = item.set_en
            this.selected_item.set_jp = item.set_jp

            this.selected_item.id = item.id

            this.selected_item.memo_kr = item.memo_kr
            this.selected_item.memo_en = item.memo_en
            this.selected_item.memo_jp = item.memo_jp

            this.selected_item.name_kr = item.name_kr
            this.selected_item.name_en = item.name_en
            this.selected_item.name_jp = item.name_jp

            this.selected_item.rarity_kr = item.rarity_kr
            this.selected_item.rarity_en = item.rarity_en
            this.selected_item.rarity_jp = item.rarity_jp

            this.selected_item.number_kr = item.number_kr
            this.selected_item.number_en = item.number_en
            this.selected_item.number_jp = item.number_jp

            this.selected_item.printedTotal_kr = item.printedTotal_kr
            this.selected_item.printedTotal_en = item.printedTotal_en
            this.selected_item.printedTotal_jp = item.printedTotal_jp

            this.dialog.memo = true
        },

        // 수량 증가
        item_increase(item){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.keyword.card_nation == "en")
            {
                item.amount_en++
            }
            else if(this.keyword.card_nation == "kr")
            {
                item.amount_kr++
            }
            else if(this.keyword.card_nation == "jp")
            {
                item.amount_jp++
            }

            this.save_card(item)
        },

        // 수량 감소
        item_decrease(item){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.keyword.card_nation == "en")
            {
                if(item.amount_en > 0){
                    item.amount_en--
                }
            }
            else if(this.keyword.card_nation == "kr")
            {
                if(item.amount_kr > 0){
                    item.amount_kr--
                }
            }
            else if(this.keyword.card_nation == "jp")
            {
                if(item.amount_jp > 0){
                    item.amount_jp--
                }
            }

            this.save_card(item)
        },

        // 순서
        sortList(){
            if(this.sequence == "번호")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => a.number_en - b.number_en)
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => a.number_kr - b.number_kr)
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => a.number_jp - b.number_jp)
                }
                if(this.align_number){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "이름")
            {
                if(this.keyword.card_nation == "en"){
                    this.list.sort((a, b) => (typeof a.name_en !== 'string' ? 1 : (typeof b.name_en !== 'string' ? -1 : a.name_en.localeCompare(b.name_en))))
                }else if(this.keyword.card_nation == "kr"){
                    this.list.sort((a, b) => (typeof a.name_kr !== 'string' ? 1 : (typeof b.name_kr !== 'string' ? -1 : a.name_kr.localeCompare(b.name_kr))))
                }else if(this.keyword.card_nation == "jp"){
                    this.list.sort((a, b) => (typeof a.name_jp !== 'string' ? 1 : (typeof b.name_jp !== 'string' ? -1 : a.name_jp.localeCompare(b.name_jp))))
                }
                if(this.align_name){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "보유수량")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => a.amount_en - b.amount_en)
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => a.amount_kr - b.amount_kr)
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => a.amount_jp - b.amount_jp)
                }
                if(this.align_amount){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "발매일")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => new Date(a.releaseDate_en).getTime() - new Date(b.releaseDate_en).getTime())
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => new Date(a.releaseDate_kr).getTime() - new Date(b.releaseDate_kr).getTime())
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => new Date(a.releaseDate_jp).getTime() - new Date(b.releaseDate_jp).getTime())
                }
                if(this.align_date){
                    this.list = this.list.reverse()
                }
            }

            // console.log(this.list.map(e => e.amount_kr))
            // console.log(this.list.map(e => e.number_kr))
            // console.log(this.list.map(e => e.name_kr))
            // console.log(this.list.map(e => e.releaseDate_kr))

            this.list_ready = true
        },

        // 검색
        search(){
            this.loading = true
            this.$http.post("/api/new/collection/select/card/search", {
                params: {
                    user_id: this.$store.state.user_id,
                    keyword: this.keyword
                }
            }).then((res) => {
                console.log("list", res.data)
                if(res.data.length){
                    this.list = res.data
                    this.list_ready = true
                    this.sortList()
                }else{
                    this.list = []
                }
                this.loading = false
            })
        },

        // 카드메모 > 전체카드에 저장
        save_memo_to_all(){
            if(confirm("제품명 전체 카드에 메모를 저장합니다\n기존에 있던 메모는 모두 삭제됩니다.")){
                for(let i=0;i<this.list.length;i++){
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_kr,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_kr,
                            amount: this.list[i].amount_kr,
                            memo: this.selected_item.memo_kr
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                }
                alert("모든 카드에 해당 메모가 저장되었습니다")
            }
        },

        // 카드메모 > 저장
        save_memo(){
            console.log(this.selected_item)
            if(this.keyword.card_nation == "kr")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.selected_item.set_kr,
                        card_nation: this.keyword.card_nation,
                        card_id: this.selected_item.id,
                        card_number: this.selected_item.number_kr,
                        amount: this.selected_item.amount_kr,
                        memo: this.selected_item.memo_kr
                    }
                }).then((res) => {
                    // console.log(res)
                    this.dialog.memo = false
                    this.search()
                })
            }
            else if(this.keyword.card_nation == "jp")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.selected_item.set_jp,
                        card_nation: this.keyword.card_nation,
                        card_id: this.selected_item.id,
                        card_number: this.selected_item.number_jp,
                        amount: this.selected_item.amount_jp,
                        memo: this.selected_item.memo_jp
                    }
                }).then((res) => {
                    // console.log(res)
                    this.dialog.memo = false
                    this.search()
                })
            }
            else if(this.keyword.card_nation == "en")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.selected_item.set_en,
                        card_nation: this.keyword.card_nation,
                        card_id: this.selected_item.id,
                        card_number: this.selected_item.number_en,
                        amount: this.selected_item.amount_en,
                        memo: this.selected_item.memo_en
                    }
                }).then((res) => {
                    // console.log(res)
                    this.dialog.memo = false
                    this.search()
                })
            }
        },

        // 카드 1장 저장 (수량증가시)
        save_card(item){
            if(this.keyword.card_nation == "kr")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: item.set_kr,
                        card_nation: this.keyword.card_nation,
                        card_id: item.id,
                        card_number: item.number_kr,
                        amount: item.amount_kr,
                        memo: item.memo_kr
                    }
                }).then((res) => {
                    // console.log(res)
                    this.load_set_info()
                })
            }
            else if(this.keyword.card_nation == "jp")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: item.set_jp,
                        card_nation: this.keyword.card_nation,
                        card_id: item.id,
                        card_number: item.number_jp,
                        amount: item.amount_jp,
                        memo: item.memo_jp
                    }
                }).then((res) => {
                    // console.log(res)
                    this.load_set_info()
                })
            }
            else if(this.keyword.card_nation == "en")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: item.set_en,
                        card_nation: this.keyword.card_nation,
                        card_id: item.id,
                        card_number: item.number_en,
                        amount: item.amount_en,
                        memo: item.memo_en
                    }
                }).then((res) => {
                    // console.log(res)
                    this.load_set_info()
                })
            }
        },

        // 모든 카드에 수량 1 증가
        async all_plus(){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.keyword.card_nation == "kr")
            {
                this.list = this.list.map(item => ({
                    ...item,
                    amount_kr: item.amount_kr + 1
                }))
            }
            else if(this.keyword.card_nation == "jp")
            {
                this.list = this.list.map(item => ({
                    ...item,
                    amount_jp: item.amount_jp + 1
                }))
            }
            else if(this.keyword.card_nation == "en")
            {
                this.list = this.list.map(item => ({
                    ...item,
                    amount_en: item.amount_en + 1
                }))
            }

            for(let i=0;i<this.list.length;i++){
                if(this.keyword.card_nation == "kr")
                {
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_kr,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_kr,
                            amount: this.list[i].amount_kr,
                            memo: this.list[i].memo_kr
                        }
                    }).then((res) => {
                        // console.log(res)
                        this.load_set_info()
                    })
                }
                else if(this.keyword.card_nation == "jp")
                {
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_jp,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_jp,
                            amount: this.list[i].amount_jp,
                            memo: this.list[i].memo_jp
                        }
                    }).then((res) => {
                        // console.log(res)
                        this.load_set_info()
                    })
                }
                else if(this.keyword.card_nation == "en")
                {
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_en,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_en,
                            amount: this.list[i].amount_en,
                            memo: this.list[i].memo_en
                        }
                    }).then((res) => {
                        // console.log(res)
                        this.load_set_info()
                    })
                }
            }
            alert("모든 카드에 수량이 1 증가하였습니다")
        },
        
        // 모든 카드에 수량 1 감소
        async all_minus(){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.keyword.card_nation == "kr")
            {
                this.list = this.list.map(item => ({
                    ...item,
                    amount_kr: item.amount_kr > 1 ? item.amount_kr - 1 : 0
                }))

                for(let i=0;i<this.list.length;i++){
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_kr,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_kr,
                            amount: this.list[i].amount_kr,
                            memo: this.list[i].memo_kr
                        }
                    }).then((res) => {
                        // console.log(res)
                        this.load_set_info()
                    })
                }
            }

            else if(this.keyword.card_nation == "jp")
            {
                this.list = this.list.map(item => ({
                    ...item,
                    amount_jp: item.amount_jp > 1 ? item.amount_jp - 1 : 0
                }))

                for(let i=0;i<this.list.length;i++){
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_jp,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_jp,
                            amount: this.list[i].amount_jp,
                            memo: this.list[i].memo_jp
                        }
                    }).then((res) => {
                        // console.log(res)
                        this.load_set_info()
                    })
                }
            }

            else if(this.keyword.card_nation == "en")
            {
                this.list = this.list.map(item => ({
                    ...item,
                    amount_en: item.amount_en > 1 ? item.amount_en - 1 : 0
                }))

                for(let i=0;i<this.list.length;i++){
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_en,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_en,
                            amount: this.list[i].amount_en,
                            memo: this.list[i].memo_en
                        }
                    }).then((res) => {
                        // console.log(res)
                        this.load_set_info()
                    })
                }
            }

            alert("모든 카드에 수량이 1 감소하였습니다")
        },

        // 초기화 (전체)
        reset(){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.keyword.card_nation == "kr")
            {
                this.$http.post("/api/new/collection/delete", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.list[0].set_kr,
                        card_nation: this.keyword.card_nation
                    }
                }).then((res) => {
                    // console.log(res)
                    alert("초기화되었습니다")
                    this.search()
                    this.load_set_info()
                })
            }

            else if(this.keyword.card_nation == "jp")
            {
                this.$http.post("/api/new/collection/delete", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.list[0].set_jp,
                        card_nation: this.keyword.card_nation
                    }
                }).then((res) => {
                    // console.log(res)
                    alert("초기화되었습니다")
                    this.search()
                    this.load_set_info()
                })
            }

            else if(this.keyword.card_nation == "en")
            {
                this.$http.post("/api/new/collection/delete", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.list[0].set_en,
                        card_nation: this.keyword.card_nation
                    }
                }).then((res) => {
                    // console.log(res)
                    alert("초기화되었습니다")
                    this.search()
                    this.load_set_info()
                })
            }
        },

        // 삭제된 저장 버튼 (legacy)
        async save(){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.keyword.card_nation == "kr")
            {
                await this.$http.post("/api/new/collection/delete", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.list[0].set_kr,
                        card_nation: this.keyword.card_nation
                    }
                }).then((res) => {
                    // console.log(res)
                })

                for(let i=0;i<this.list.length;i++){
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_kr,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_kr,
                            amount: this.list[i].amount_kr,
                            memo: this.list[i].memo_kr
                        }
                    }).then((res) => {
                        // console.log(res)
                    })
                }
            }

            else if(this.keyword.card_nation == "jp")
            {
                await this.$http.post("/api/new/collection/delete", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.list[0].set_jp,
                        card_nation: this.keyword.card_nation
                    }
                }).then((res) => {
                    // console.log(res)
                })

                for(let i=0;i<this.list.length;i++){
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_jp,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_jp,
                            amount: this.list[i].amount_jp,
                            memo: this.list[i].memo_jp
                        }
                    }).then((res) => {
                        // console.log(res)
                    })
                }
            }

            else if(this.keyword.card_nation == "en")
            {
                await this.$http.post("/api/new/collection/delete", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.list[0].set_en,
                        card_nation: this.keyword.card_nation
                    }
                }).then((res) => {
                    // console.log(res)
                })

                for(let i=0;i<this.list.length;i++){
                    this.$http.post("/api/new/collection/insert", {
                        params: {
                            user_id: this.$store.state.user_id,
                            set_name: this.list[i].set_en,
                            card_nation: this.keyword.card_nation,
                            card_id: this.list[i].id,
                            card_number: this.list[i].number_en,
                            amount: this.list[i].amount_en,
                            memo: this.list[i].memo_en
                        }
                    }).then((res) => {
                        // console.log(res)
                    })
                }
            }


            alert("저장되었습니다.")
        },

        calculateProbability(count, totalNumber) {
            // console.log(count)
            // console.log(totalNumber)
            // count와 totalNumber가 숫자가 아니거나 totalNumber가 0인 경우 확률을 계산할 수 없음
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0; // 확률을 계산할 수 없으므로 0%로 표시
            }

            const probability = (count / totalNumber) * 100; // 확률 계산 (백분율로 표시)
            return parseInt(probability); // 소수점 두 자리까지 표시
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>