<template>
    <div>
        <Mobile
            v-if="$vuetify.breakpoint.mobile"
        />

        <Pc
            v-if="!$vuetify.breakpoint.mobile"
        />
    </div>
</template>
<script>
import Mobile from "./dashboard/Mobile"
import Pc from "./dashboard/Pc"

export default {
    components: {
        Mobile,
        Pc
    }
}
</script>