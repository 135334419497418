<template>
    <div class="mobile">
        <!-- 상단 -->
        <Header
            @nationUpdated="nationUpdated_handler"
        />

        <!-- 검색 -->
        <v-sheet class="py-3 px-5">
            <div class="d-flex">
                <!-- 시리즈 -->
                <v-autocomplete
                    height="40"
                    style="width:140px;"
                    class="rounded-15 font-weight-bold mr-1 shrink"
                    label="시리즈"
                    placeholder="전체"
                    persistent-placeholder
                    dense
                    outlined
                    hide-details
                    :items="select_list.selected_series"
                    v-model="keyword.series"
                    :menu-props="{ offsetY: true }"
                >
                    <template v-slot:append>
                        <v-icon class="pt-0 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-autocomplete>

                <!-- 제품명 -->
                <v-autocomplete
                    height="40"
                    class="mr-1 rounded-15 font-weight-bold"
                    hide-details
                    dense
                    outlined
                    placeholder="제품명"
                    :items="set_list"
                    v-model="keyword.text"
                    @keyup.enter="search()"
                >
                    <template v-slot:append>
                        <v-icon size="14" class="pt-5px mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-autocomplete>

                <!-- 검색 버튼 -->
                <v-btn
                    height="40"
                    depressed
                    dark
                    class="rounded-10"
                    color="primary"
                    style="font-size:13px; font-weight:bold;"
                    @click="search()"
                >
                    검색
                </v-btn>
            </div>
        </v-sheet>

        <div
            class="d-flex justify-space-between mx-auto mt-2"
            style="width:268px;"
        >
            <!-- 순서 -->
            <v-select
                height="40"
                style="width:140px; background:white;"
                class="rounded-15 font-weight-bold mr-1"
                dense
                outlined
                hide-details
                v-model="sequence"
                :items="select_list.sequence"
            >
                <template v-slot:selection="{ item }">
                    <span style="font-size:12px;" class="pl-2">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon class="pt-0 mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
            <!-- 목록 방식 (card) -->
            <v-btn
                width="45"
                height="40"
                x-small
                dark
                depressed
                class="rounded-10 mr-1"
                style="cursor:pointer;"
                :color="list_type=='card'? '#23D2E2':'#DFDFDF'"
                @click="list_type='card'"
            >
                <v-icon
                >
                    mdi-image
                </v-icon>
            </v-btn>

            <!-- 목록 방식 (list) -->
            <v-btn
                width="45"
                height="40"
                x-small
                dark
                depressed
                class="rounded-10"
                style="cursor:pointer;"
                :color="list_type=='list'? '#23D2E2':'#DFDFDF'"
                @click="list_type='list'"
            >
                <v-icon>
                    mdi-menu
                </v-icon>
            </v-btn>
        </div>

        <!-- 본문 -->
        <v-sheet
            v-if="!loading && currentPageList.length"
            class="mt-2 d-flex justify-center rounded-15"
            color="transparent"
        >
            <!-- 목록 방식 (card) -->
            <v-row
                v-if="list_type == 'card'"
                dense
            >
                <v-col
                    v-for="(item, index) in currentPageList" :key="index"
                    cols="12"
                    class="mb-3"
                >
                    <v-sheet
                        width="268"
                        height="410"
                        class="pa-6 rounded-15 mx-auto deck_shadow"
                        style="cursor:pointer; position:relative;"
                    >
                        <v-img
                            v-if="card_nation == 'kr'"
                            height="50"
                            width="150"
                            contain
                            class="mx-auto mb-8"
                            :src="item.image? '/upload/set_kr_image/'+encodeURI(item.image):require('@/assets/logos/icon_grey.svg')"
                        ></v-img>
                        <v-img 
                            v-if="card_nation == 'jp'"
                            height="50"
                            width="150"
                            contain
                            class="mx-auto mb-8"
                            :src="item.image? '/upload/set_jp_image/'+encodeURI(item.image):require('@/assets/logos/icon_grey.svg')"
                        ></v-img>
                        <v-img 
                            v-if="card_nation == 'en'"
                            height="50"
                            width="150"
                            contain
                            class="mx-auto mb-8"
                            :src="item.image? '/upload/set_en_image/'+encodeURI(item.image):require('@/assets/logos/icon_grey.svg')"
                        ></v-img>
                        <p
                            style="height:60px; overflow:hidden; width:180px; word-wrap:break-word"
                            class="mt-4 mb-2 font-weight-medium text-body-2 mx-6 text-center"
                        >
                            {{item.name}}
                        </p>
                        <p
                            class="mb-0 text-center text-body-2 font-weight-light"
                        >
                            시리즈 : {{item.series}}
                        </p>
                        <p
                            class="text-center text-body-2 font-weight-light"
                        >
                            발매일 : {{new Date(item.release_datetime).toLocaleDateString()}}
                        </p>
                        <p
                            class="mb-0 text-center text-body-1 font-weight-bold"
                        >
                           {{parseInt(item.totalNumber)}}개
                        </p>

                        <p
                            class="text-center text-caption primary--text"
                        >
                           +{{(item.totalCard - parseInt(item.totalNumber)) < 0? 0 : (item.totalCard - parseInt(item.totalNumber))}} 시크릿
                        </p>

                        <v-progress-linear
                            color="primary"
                            background-color="grey lighten-3"
                            :value="calculateProbability((item.count+item.count_secret), item.totalNumber)"
                        ></v-progress-linear>

                        <p
                            class="mt-2 mb-1 text-center"
                            style="font-size:10px;"
                        >
                            {{(item.count+item.count_secret)}} / {{parseInt(item.totalNumber)}}
                        </p>

                        <v-sheet
                            width="88"
                            height="32"
                            class="mt-6 d-flex justify-center align-center mx-auto rounded-20 custom_shadow"
                        >
                            <span
                                class="text-body-2 font-weight-medium"
                                :class="calculateProbability((item.count+item.count_secret), item.totalCard) == 100? 'primary--text':''"
                            >
                                {{calculateProbability((item.count+item.count_secret), item.totalCard)}}%
                            </span>
                        </v-sheet>

                        <v-btn
                            style="position:absolute; bottom:16px; right:12px;"
                            dark
                            depressed
                            width="24"
                            height="24"
                            class="mr-6px pa-0 font-weight-bold rounded-circle"
                            color="primary"
                            @click="read(item)"
                        >
                            <v-icon color="white" size="14">mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </v-sheet>
                </v-col>
            </v-row>

            <!-- 목록 방식 (list) -->
            <v-row
                v-if="list_type == 'list'"
                dense
            >
                <v-col
                    v-for="(item, index) in currentPageList" :key="index"
                    cols="12"
                    class="mb-1"
                >
                    <v-sheet
                        width="268"
                        class="px-6 pt-1 pb-3 rounded-15 mx-auto deck_shadow"
                        style="cursor:pointer; position:relative;"
                    >
                        <p
                            style="height:60px; overflow:hidden; width:180px; word-wrap:break-word"
                            class="mt-4 mb-0 font-weight-medium text-body-2 mx-6 text-center"
                        >
                            {{item.name}}
                        </p>

                        <v-progress-linear
                            color="primary"
                            background-color="grey lighten-3"
                            :value="calculateProbability((item.count+item.count_secret), item.totalNumber)"
                        ></v-progress-linear>

                        
                        <div
                            class="d-flex align-center justify-space-between mt-1"
                        >
                            <p
                                class="font-weight-medium mb-0"
                                style="font-size:10px; width:60px;"
                                :class="calculateProbability((item.count+item.count_secret), item.totalCard) == 100? 'primary--text':''"
                            >
                                {{calculateProbability((item.count+item.count_secret), item.totalCard)}}%
                            </p>

                            <v-btn
                                dark
                                depressed
                                width="24"
                                height="24"
                                class="mr-6px pa-0 font-weight-bold rounded-circle"
                                color="primary"
                                @click="read(item)"
                            >
                                <v-icon color="white" size="14">mdi-square-edit-outline</v-icon>
                            </v-btn>

                            <p
                                class="mb-0 text-end"
                                style="font-size:10px; width:60px;"
                            >
                                {{(item.count+item.count_secret)}} / {{parseInt(item.totalNumber)}}
                            </p>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>

            <!-- 검색결과가 없을 시 -->
            <v-row
                v-if="!currentPageList.length"
                no-gutters
            >
                <v-col class="py-16 text-center">
                    <v-icon x-large color="primary" class="mb-4">mdi-close-octagon-outline</v-icon>
                    <p class="primary--text">검색 결과가 없습니다</p>
                </v-col>
            </v-row>
        </v-sheet>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            indeterminate
            class="d-flex mx-auto my-10"
            color="primary"
            size="80"
        ></v-progress-circular>

        <!-- 페이징 -->
        <v-pagination
            class="mt-8 mobile mb-4"
            color="primary"
            v-model="page"
            :length="pageLength"
            :total-visible="7"
        ></v-pagination>

        <!-- 콜렉션북 안내문구 -->
        <v-sheet
            class="rounded-10 d-flex align-center justify-center px-4 mx-5 mb-4"
            height="40"
        >
            <v-icon color="#ADAFCA" class="mr-2">mdi-information-outline</v-icon>
            <p
                class="my-0"
                style="font-size:10px; line-height:12px;"
            >
                콜렉션북의 콜렉션%는 카드DB의 추가/수정 등의 이유로 달라질 수 있습니다.
            </p>
        </v-sheet>

        <BannerView
            v-if="$store.state.ads_banner.collection"
            type="collection"
            width="100%"
            :height="46/360*100+'vw'"
            :round="'rounded-0'"
        />
    </div>
</template>
<script>
import Header from "../components/Header"
import BannerView from '@/components/BannerView'

export default {
    components: {
        Header,
        BannerView
    },

    data: () => ({
        // 선택목록
        select_list: {
            // 제품명
            set: {
                kr: [],
                en: [],
                jp: []
            },

            // 시리즈
            series: {
                kr: [],
                en: [],
                jp: []
            },

            // 선택한 시리즈
            selected_series: [],

            // 국가 선택
            card_nation: [
                {value: "kr", name: "국내판"},
                {value: "en", name: "북미판"},
                {value: "jp", name: "일본판"}
            ],

            // 순서
            sequence: [
                "최신순",
                "오래된순",
                "수집도 높은순",
                "수집도 낮은순",
                "최근 입력순",
            ]
        },

        // 순서
        sequence: "최신순",

        // 발매국가
        card_nation: "kr",

        // 키워드
        keyword: {
            text: "",
            series: "스칼렛&바이올렛"
        },

        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,

        list_type: "card",

        user_id: null,

        loading: true
    }),

    watch: {
        card_nation(){
            this.seriesCheckAndSearch()
        },

        // 페이징
        page(newVal) {
            this.$router.replace({ query: { ...this.$route.query, page: newVal } })
            this.currentPageList = this.list.slice((this.page-1)*9,(this.page)*9)
            window.scrollTo(0, 0)
        },

        // 순서
        sequence(){
            this.sortList()
        }
    },

    async mounted(){
        if(this.$route.query.nation){
            this.card_nation = this.$route.query.nation
        }

        // 시리즈 목록 불러오기
        await this.loadSeriesList()


        // 제품명 목록 (검색 select_list용)
        await this.loadSetsListSelection()

        if(this.$route.query.user_id){
            this.user_id = this.$route.query.user_id
        }else{
            this.user_id = this.$store.state.user_id
        }

        // 검색
        this.seriesCheckAndSearch()
    },

    computed: {
        // 제품명
        set_list(){
            switch(this.card_nation){
                case 'en' : return this.select_list.set.en
                case 'kr' : return this.select_list.set.kr
                case 'jp' : return this.select_list.set.jp
            }
        },
    },

    methods: {
        // 시리즈 확인후 검색
        seriesCheckAndSearch(){
            if(this.card_nation == "kr"){
                this.select_list.selected_series = this.select_list.series.kr
                this.keyword.series = this.select_list.selected_series[0]
                this.search()
            }
            else if(this.card_nation == "en"){
                this.select_list.selected_series = this.select_list.series.en
                this.keyword.series = this.select_list.selected_series[0]
                this.search()
            }
            else if(this.card_nation == "jp"){
                this.select_list.selected_series = this.select_list.series.jp
                this.keyword.series = this.select_list.selected_series[0]
                this.search()
            }
        },
        
        // 제품명 목록 불러오기
        async loadSetsListSelection(){
            // 한글
            await this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.filter(a => !a.name.includes("(미발매)")).map(a => a.name)
            })

            // 영어
            await this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.filter(a => !a.name.includes("(미발매)")).map(a => a.name)
            })

            // 일어
            await this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "jp"
                }
            }).then(res => { 
                this.select_list.set.jp = res.data.filter(a => !a.name.includes("(미발매)")).map(a => a.name)
            })
        },

        nationUpdated_handler(nation){
            console.log(nation)
            this.card_nation = nation
            this.search()
        },

        // 시리즈 목록 불러오기
        loadSeriesList(){
            // 한글
            this.$http.post('/api/card/select/series/kr')
            .then(res => { 
                console.log(res)
                this.select_list.series.kr = res.data.sort((a, b) => new Date(b.release_datetime).getTime() - new Date(a.release_datetime).getTime()).map(a => a.series).filter(e => e!=null)
                this.select_list.selected_series = res.data.sort((a, b) => new Date(b.release_datetime).getTime() - new Date(a.release_datetime).getTime()).map(a => a.series).filter(e => e!=null)
            })

            // 영어
            this.$http.post('/api/card/select/series/en')
            .then(res => { 
                console.log(res)
                this.select_list.series.en = res.data.sort((a, b) => new Date(b.release_datetime).getTime() - new Date(a.release_datetime).getTime()).map(a => a.series).filter(e => e!=null)
            })

            // 일어
            this.$http.post('/api/card/select/series/jp')
            .then(res => { 
                console.log(res)
                this.select_list.series.jp = res.data.sort((a, b) => new Date(b.release_datetime).getTime() - new Date(a.release_datetime).getTime()).map(a => a.series).filter(e => e!=null)
            })
        },

        // 검색
        search(){
            this.loading = true
            this.$http.post("/api/new/collection/select/set/search/"+this.card_nation, {
                params: {
                    card_nation: this.card_nation,
                    series: this.keyword.series,
                    keyword: this.keyword.text,
                    user_id: this.user_id
                }
            }).then((res) => {
                console.log(res)

                // 페이징
                if (this.$route.query.page) {
                    this.page = parseInt(this.$route.query.page)
                } else {
                    this.page = 1
                }

                this.list = res.data
                this.currentPageList = res.data.slice(0,9)
                this.pageLength = Math.ceil(res.data.length/9)
                this.list_ready = true
                this.loading = false
            })
        },

        // 순서
        sortList(){
            if(this.sequence == "최신순"){
                if(this.card_nation == "en"){
                    this.list.sort((a, b) => new Date(b.release_datetime).getTime() - new Date(a.release_datetime).getTime())
                }else if(this.card_nation == "kr"){
                    this.list.sort((a, b) => new Date(b.release_datetime).getTime() - new Date(a.release_datetime).getTime())
                }else if(this.card_nation == "jp"){
                    this.list.sort((a, b) => new Date(b.release_datetime).getTime() - new Date(a.release_datetime).getTime())
                }
            }
            else if(this.sequence == "오래된순"){
                if(this.card_nation == "en"){
                    this.list.sort((a, b) => new Date(a.release_datetime).getTime() - new Date(b.release_datetime).getTime())
                }else if(this.card_nation == "kr"){
                    this.list.sort((a, b) => new Date(a.release_datetime).getTime() - new Date(b.release_datetime).getTime())
                }else if(this.card_nation == "jp"){
                    this.list.sort((a, b) => new Date(a.release_datetime).getTime() - new Date(b.release_datetime).getTime())
                }
            }
            this.currentPageList = this.list.slice(0,9)
            this.list_ready = true
            this.page = 1
        },

        // 카드선택으로 이동
        read(item){
            // 본인 꺼에만 적용
            if(this.user_id == this.$store.state.user_id){
                this.$router.push('/collection/collect?nation='+ this.card_nation +'&set_name=' + encodeURIComponent(item.name))
            }
        },

        // 수집률 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

/* 메뉴 */
.v-btn::before {
    background-color: transparent;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 12px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 12px;
}

/* 페이징 */
::v-deep .mobile .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}

</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>
<style scoped>
.deck_shadow{
    box-shadow: 0px 0px 16px 8px #eeeeee !important;
}
</style>